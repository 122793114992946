import React from "react"
import Header from '../components/header'
import Footer from '../components/footer'
import Container from '../components/container'
import SEO from "../components/seo"
import icon from "../images/info/UTC_App_iOS_Icon.svg"

export default function Home() {
  return (
      <div>
        <SEO title="Info" description="Landing Page about Universial Time Complication App" />
        <Header captionText="App"/>
        <Container>
          <p>
            <div>Universal Time Complication is an Apple Watch only App for displaying adjusted time on the watch face.</div>
            <div>You can simply adjust the time by adding an offset to either the current local time or the Coordinated Universal Time (UTC). The maximum offsets are going from -23 hours and -59 minutes to +23 hours and +59 minutes.</div>
          </p>
          <p>
            <div>The App provides a Watch Face Complication with the following combinations:</div>
            <div><span>&#183;</span>current local time</div>
            <div><span>&#183;</span>Coordinated Universal Time (UTC)</div>
            <div><span>&#183;</span>adjusted time</div>
            <div><span>&#183;</span>current local time + Coordinated Universal Time (UTC) (not on all watch faces available)</div>
            <div><span>&#183;</span>current local time + adjusted time (not on all watch faces available)</div>
            <div><span>&#183;</span>adjusted time + Coordinated Universal Time (UTC) (not on all watch faces available)</div>
          </p>
          <p>The time format will always be “HH:MM”. “am/pm” is not supported.</p>
          <p>The complication will show the offset to the current local time. If there is a difference of +3 hours between the displayed time in the Complication and the local time the Complication will be “HH:MM, 3”. If the difference is -3 hours the complication will be “HH:MM, -3”.</p>
        {/* <img src={icon} alt="Icon"/> */}
        </Container>
        <Footer />
      </div>
      )
}
